<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        v-for="(company, index) in companies"
        :key="index"
        class="py-1"
      >
        <a
          class="wrapp_company text-left pa-2"
          :href="company.href"
          target="_blank"
        >
          <b>{{ company.name }} - </b>{{ company.text }}
        </a>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "companies",
  data: () => ({
    companies: [
      {
        name: "Microwd",
        text: "La fintech para los desbancarizados.",
        href: "https://www.microwd.es/",
      },
      {
        name: "techô",
        text: "La inversión inmobiliaria que contribuye al fin del sinhogarismo.",
        href: "https://tutecho.es/",
      },
      {
        name: "TuuuLibrería",
        text: "Tú decides lo que pagas por los libros.",
        href: "https://www.tuuulibreria.org/#/",
      },
      {
        name: "Fin de raza",
        text: "Libros en un futuro que arde.",
        href: "https://www.finderaza.com/",
      },
      {
        name: "Escot.App",
        text: "Compas del comercio.",
        href: "https://www.escot.app/",
      },
    ],
  }),
  mounted() {
    this.changeToSecondPage(true);
  },
  methods: {
    ...mapMutations(["changeToSecondPage"]),
  },
};
</script>

<style scoped>
.wrapp_company {
  color: #000000;
  border: 1px solid #000000;
  display: block;
  font-size: 1.1rem;
  text-decoration: none;
}
</style>
